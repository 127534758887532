import * as React from "react"
import { Link } from "gatsby"
import Banner from "../images/banner1.png"

import Logo from "../images/logo.png"

import Sec2A from "../images/carousel/facilities.png"
import Sec2B from "../images/carousel/visitor.png"
import Sec2C from "../images/carousel/news.png"
import Sec2D from "../images/carousel/smarthouse.png"
import Sec2E from "../images/carousel/security.png"

import Sec3A from "../images/sec3-1.png"
import Sec3C from "../images/sec3-3.png"

import Sec4A from "../images/resident/epass.png"
import Sec4B from "../images/resident/eform.png"
import Sec4C from "../images/resident/ebill.png"
import Sec4D from "../images/resident/facilities.png"
import Sec4E from "../images/resident/complaint.png"
import Sec4F from "../images/resident/kibot.png"
import Sec4G from "../images/resident/sos.png"
import Sec4H from "../images/resident/log.png"

import Sec5 from "../images/management.png"

import Sec6A from "../images/security/pass.png"
import Sec6B from "../images/security/qr.png"
import Sec6C from "../images/security/control.png"
import Sec6D from "../images/security/supervise.png"

const renderSec2Item = (img, title, easeDelay, colStart, imgW) =>{
  return (
    <div className={"md:p-5 grid grid-cols-1 md:col-span-1 " + colStart}  data-sal="zoom-in"  data-sal-duration="500" data-sal-delay={easeDelay} data-sal-easing="ease-out-back" >
      <img className={"mx-auto " + imgW} src={img} alt="banner" />
      <p className="text-center mt-2 text-yellow-900 font-light md:text-tiny text-2xs">{title}</p>
    </div>
  )
}

const IndexPage = () => {
  return (
    <>
    {/* header */}
    <div className="m-5 grid grid-cols-12">
        <div data-sal="zoom-in" data-sal-duration="300" data-sal-easing="ease" className="h-full flex flex-wrap content-center">
          <img className={"mx-auto w-10"} src={Logo} alt="kbms-logo" />
        </div>
    </div>

    {/* section 1 */}
    <div className="md:mt-32 mt-10 grid grid-cols-2 gap-2">
        <div data-sal="zoom-in" data-sal-duration="500" data-sal-delay="3000" data-sal-easing="ease" className="h-full flex flex-wrap content-center">
          <p className="md:p-5 subpixel-antialiased md:text-5xl text-lg text-right font-bold w-full text-center text-yellow-900">CONNECTING MADE EASY!</p>
          </div>
        <div className="pr-10" data-sal="zoom-in" data-sal-duration="500" data-sal-delay="500" data-sal-easing="ease-out-back"><img src={Banner} alt="banner" /></div>
    </div>

    {/* section 2 */}
    <div className="grid md:grid-cols-5 grid-cols-6 md:mt-48 mt-20 md:px-48 px-2 gap-5">
        {renderSec2Item(Sec2E, 'Security Guard', 600, 'col-span-2 p-5') }
        {renderSec2Item(Sec2A, 'Facilities', 700, 'col-span-2 p-5') }
        {renderSec2Item(Sec2B, 'Visitor Management', 800, 'col-span-2 p-5') }
        {renderSec2Item(Sec2C, 'News & Management', 900, 'col-span-3', 'md:w-32 w-16') }
        {renderSec2Item(Sec2D, 'Smart House', 1000, 'col-span-3', 'md:w-32 w-16') }
    </div>

    {/* section 3 */}
    <div className="grid grid-cols-8 mt-10 p-2 gap-5 md:mt-48 bg-repeat-x bg-sec3">
      <div data-sal="zoom-in" data-sal-duration="500" data-sal-delay="300" data-sal-easing="ease-out-back"  className="col-span-2">
        <img className="mx-auto md:mt-0 mt-12" src={Sec3A} alt="sec3-1" />
      </div>

      <div className="col-span-4" >
        <p className="subpixel-antialiased md:text-3xl text-xl font-bold w-full text-center text-yellow-900">ONE STOP PLATFORM!</p>
        <p className="md:mt-5 mt-0 md:p-5 subpixel-antialiased md:text-lg text-sm font-light w-full text-center text-yellow-900">
          A leading services provider specially designated to help on creating a bond between house owner, tenants, management and comunity
        </p>
      </div>

      <div data-sal="zoom-in" data-sal-duration="500" data-sal-delay="500" data-sal-easing="ease-out-back"  className="col-span-2">
        <img className="mx-auto md:w-40 w-14 md:mt-0 mt-12" src={Sec3C} alt="sec3-3" />
      </div>
    </div>

    {/* section 4 */}
    <div className="grid grid-cols-auto mt-10 p-2 gap-5 md:mt-48">
      <div>
        <p className="subpixel-antialiased md:text-3xl text-xl font-bold w-full text-center text-yellow-900">RESIDENTS!</p>
        <p className="subpixel-antialiased md:text-lg text-sm font-light w-full text-center text-yellow-900">
          KBMS come with mobile apps features to maximize the usage among the resident. These features includes:
        </p>
      </div>
    </div>

    <div className="grid md:grid-cols-8 grid-cols-6 p-2">
        {renderSec2Item(Sec4A, 'ePass', 300, 'md:col-span-2 col-span-2 p-5') }
        {renderSec2Item(Sec4B, 'eForm', 350, 'md:col-span-2 col-span-2 p-5') }
        {renderSec2Item(Sec4C, 'eBill', 400, 'md:col-span-2 col-span-2 p-5') }
        {renderSec2Item(Sec4D, 'Facilities Booking', 450, 'md:col-span-2 col-span-3 p-5', 'md:w-32 w-16') }
        {renderSec2Item(Sec4E, 'Complaint', 300, 'md:col-span-2 col-span-3 p-5', 'md:w-32 w-16') }
        {renderSec2Item(Sec4F, 'Ki-Bot', 350, 'md:col-span-2 col-span-2 p-5') }
        {renderSec2Item(Sec4G, 'SOS', 400, 'md:col-span-2 col-span-2 p-5') }
        {renderSec2Item(Sec4H, 'LogBook', 450, 'md:col-span-2 col-span-2 p-5') }
    </div>

    {/* section 5 */}
    <div className="grid grid-cols-6 mt-10 md:px-40  px-2 gap-5 md:mt-48">
      <div className="col-span-3">
        <p className="subpixel-antialiased md:text-3xl text-xl font-bold w-full text-right text-yellow-900">MANAGEMENT</p>
        
        <div className="md:mt-10 grid grid-cols-6 gap-5">
          <p className="col-span-6 md:col-start-3 md:col-end-7 w-28 subpixel-antialiased md:text-lg text-sm font-light w-full text-right text-yellow-900">
          Meanwhile, KBMS also (designated) property’s management in helping them to supervise workforce as well as keeping the data storage entry.
          </p>
          <p className="col-span-6 md:col-start-3 md:col-end-7 subpixel-antialiased md:text-lg text-sm font-light w-full text-right text-yellow-900">
          Centralise front office operation
          Manage daily task & property account
          Data property in one repository
          Organized money flow record
          </p>
        </div>
      </div>

      <div className="col-span-3">
      {renderSec2Item(Sec5, '', 450, 'md:col-span-3 col-span-3', 'md:-mt-6 mt-8') }
      </div>
    </div>

    {/* section 6 */}
    <div className="grid grid-cols-6 mt-10 md:px-60 px-2 md:mt-48">

      <div className="md:col-span-4 col-span-3 md:mt-20 -mt-2 pr-10">
        <div className="grid grid-cols-4 md:gap-10 mt-12">
          {renderSec2Item(Sec6A, 'ePass Registration', 300, 'col-span-2 p-2', 'md:w-32 w-100') }
          {renderSec2Item(Sec6B, 'QR Pass Access', 350, 'col-span-2 p-2','md:w-32 w-20') }
          {renderSec2Item(Sec6C, 'Control Access', 400, 'col-span-2 p-2','md:w-32 w-20') }
          {renderSec2Item(Sec6D, 'Supervised Area', 450, 'col-span-2 p-2','md:w-32 w-20') }
        </div>
      </div>

      <div className="md:col-span-2 col-span-3 pr-4">
        <p className="subpixel-antialiased md:text-3xl text-xl font-bold w-full text-yellow-900">SECURITY</p>
        
        <div className="md:mt-10 grid grid-cols-6 gap-5">
          <p className="col-span-6 w-28 subpixel-antialiased md:text-lg text-sm font-light w-full text-yellow-900">
            To ensure tha safety of the resident, we are enhancing the apps to be integrated with the KBMS Guard Apps. We believe this apps will help resident in many ways include
          </p>
          <p className="col-span-6 subpixel-antialiased md:text-lg text-sm font-light w-full text-yellow-900">
            Monitor and secure the entrance <br />
            Fast action helper by sounding alarm
          </p>
        </div>
      </div>
    </div>

    {/* section 7 */}
    <div className="grid grid-cols-6 mt-10 p-2 gap-5 md:mt-48">
      <p className="col-span-6  subpixel-antialiased md:text-3xl text-xl font-bold w-full text-center text-yellow-900">AROUND SERVICE</p>
      <p className="col-span-6 md:px-80 px-10 subpixel-antialiased md:text-lg text-center text-sm font-light w-full text-center text-yellow-900">
      In future, KBMS aim to have the integration services within the residency compound. approach is to ease resident's daily routine and enable resident to enjoy full range services
      </p>
    </div>

    {/* section 8 */}
    <div className="md:h-24 h-16 grid grid-cols-6 mt-10 p-2 md:mt-48 bg-gradient-to-r from-yellow-500 yellow-50 to-yellow-900">
      <p className="md:mt-4 mt-2 col-span-6  subpixel-antialiased md:text-3xl text-xl font-bold w-full text-center text-white">LETS GET CONNECTED. SHALL WE ?</p>
    </div>

    {/* section 9 */}
    <div className="grid grid-cols-12 my-10  gap-5 md:my-12">
      <p className="col-start-1 col-end-8 px-5 subpixel-antialiased text-left md:text-sm text-2xs  font-light uppercase">
        System by Mr Tech System Holdings Sdn Bhd (1370083-M)
      </p>
      <Link className="col-start-8 col-end-13 pr-5 md:px-5 subpixel-antialiased text-right md:text-sm text-2xs font-light w-full" to="/terms">Terms & Conditions</Link>
    </div>

    </>
  )
}

export default IndexPage
